import { useQuery } from '@tanstack/react-query';

import { OrganizationSiteResponse } from '@ux/api/api-types';

export default function useSite() {
  const { data: site } = useQuery<OrganizationSiteResponse>({
    queryKey: ['site'],
    enabled: false,
  });

  return { site };
}
